
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import { IBackOptions } from "@/models/BackOptions";
import { TabbarIds } from "@/enums/HousfyPage";
import { resolveRoutePath } from "@/router";
import { IPropertyHistoryItems } from "@/models/PropertyHistory";
import { IPropertyInfo } from "@/models/PropertyInfo";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import { IHousfyError } from "@/models/HousfyError";
import PropertyHistoryShimmer from "@/components/PropertyHistoryShimmer.vue";
import PropertyHistory from "@/components/PropertyHistory_REFACTOR_OLD.vue";
import Layout from "@/components/layout/Layout.vue";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyAnchor } from "housfy-ui-lib";
import ContactPerson from "@/components/ContactPerson_REFACTOR_OLD.vue";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";

@Component({
  name: "History",
  components: {
    HousfyPage,
    PropertyHistoryShimmer,
    PropertyHistory,
    Layout,
    HousfySvg,
    HousfyAnchor,
    ContactPerson,
  },
})
export default class History extends Vue {
  headerTitle = "";
  activeTabId = TabbarIds.ACTIVITY;
  propertyInfo: IPropertyInfo | null = null;
  propertyHistory: IPropertyHistoryItems = { items: [] };
  loading = false;

  propertyUuid = this.$route.params.propertyUuid;
  propertyDeal = this.$route.params.deal;

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }

  async mounted() {
    try {
      this.loading = true;
      this.propertyInfo = await api
        .property()
        .retrievePropertyInfo(this.propertyUuid);
      this.headerTitle = this.propertyInfo?.fullAddress ?? "";
      this.propertyHistory = await api
        .property()
        .retrievePropertyHistory(this.propertyUuid);
    } catch (error) {
      ErrorService.handleGenericError(error as IHousfyError);
    } finally {
      this.loading = false;
    }
  }
}
